@import '../../common/Modal/ModalExports.scss';

.sitemapModalOverlay {
    @include overlay;

    background-color: #66BB6A;
}

.sitemapModalContent {
    position: relative;
    height: 100%;
    overflow: auto;
}

.sitemapLinks {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 120px 0 0 200px;
    
    @media (max-width: 900px) {
        padding: 60px 0 0 60px;
    }
}

.sitemapLink {
    font-size: 30px;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    display: block;
    padding: 5px 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    
    &:hover {
       color: #24292e;
    }
}

.siteMapSubLinks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 5px 20px 5px;
    color: #ffffff;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.siteMapSubLink {
    font-size: 20px;
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
    padding: 5px 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    
    &:hover {
       color: #24292e;
    }
}

.sitemapTitle {
    font-size: 56px;
    text-transform: uppercase;
    font-family: 'MuseoSans-700', sans-serif;
    color: #ffffff;
    padding-bottom: 80px;

    @media (max-width: 600px) {
        font-size: 32px;
        padding-bottom: 40px;
    }
}

.sitemapLogoWrapper {
    position: sticky;
    display: inline-block;
    bottom: 80px;
    left: calc(100% - 160px);

    svg {
        fill: #ffffff;
    }

    @media (max-width: 600px) {
        bottom: 35px;
        left: calc(100% - 115px);
    }
}

.sitemapCloseWrapper {
    position: sticky;
    display: inline-block;
    top: 35px;
    left: calc(100% - 70px);
    cursor: pointer;
    z-index: 1;

    svg {
        stroke: #ffffff;
    }
}

.sitemapBackgraundWrapper {
    position: absolute;
    top: 0;
    z-index: -1;
    display: flex;
    width: 100%;
}

.sitemapModalVectorBigger {
    display: flex;
    height: 100%;
    width: 100%;
    fill: #A5D6A7;
}

.sitemapModalVector {
    display: flex;
    height: 100%;
    width: 55%;
    fill: #A5D6A7;
}