.subscribeForm {
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	background-color: #24292e;
	padding: 55px 0;
}

.subscribeFormContent {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;

	@media (max-width: 530px) {
		flex-wrap: wrap;
	}
}

.subscribeFormContentWrapper {
	margin: 0 20px;
}

.subscribeFormTitle {
	font-size: 41px;
	font-family: 'MuseoSans-700', sans-serif;
	margin-top: 10px;
	text-transform: uppercase;
	color: #ffffff;

	@media (max-width: 1175px) {
		font-size: 32px;
	}

	@media (max-width: 768px) {
		font-size: 24px;
	}

	@media (max-width: 530px) {
		text-align: center;
	}
}

.subscribeFormSubTitle {
	color: #ffffff;
	padding-top: 10px;

	@media (max-width: 530px) {
		text-align: center;
	}
}

.subscribeFormVector {
	position: absolute;
	fill: #586069;
	top: -70%;
	right: 20%;
}

.subscribeFormInput {
	background: #ffffff;
	border: 2px solid transparent;
	box-sizing: border-box;
	border-radius: 8px;
	width: 270px;
	font-size: 14px;
	padding: 0 15px;
	height: 40px;
	margin: 10px 20px 0 0;
	outline: none;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;

	&::placeholder {
		color: #959da5;
	}

	&:focus {
		border: 2px solid #7e57c2;
	}

	@media (max-width: 1024px) {
		margin: 10px 0 0;
	}
}

.subscribeFormInputError {
	background: #ffffff;
	border: 1px solid #d32f2f;
	color: #d32f2f;
	box-sizing: border-box;
	border-radius: 8px;
	width: 270px;
	font-size: 14px;
	padding: 0 15px;
	height: 40px;
	margin: 10px 20px 0 0;
	outline: none;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;
}

.subscribeFormButton {
	width: 144px;
	height: 40px;
	margin-top: 20px;
	border: 2px solid #777a7d;
	border-radius: 8px;
	background: transparent;
	color: #ffffff;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 600;
	cursor: pointer;
	outline: none;

	&:hover {
		border: 2px solid #ffffff;
		box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
		-webkit-appearance: none;
	}

	&:active {
		border: 2px solid #ffffff;
		background: #ffffff;
		color: #66bb6a;
	}
}

.subscribeFormSuccess {
	color: #66bb6a;
	font-size: 13px;
	padding: 5px 0 0 15px;
}

.subscribeFormError {
	color: #f32121;
	font-size: 13px;
	padding: 5px 0 0 15px;
}

.subscribeFormContentForm {
	display: flex;
	justify-content: center;
	align-items: baseline;

	@media (max-width: 1024px) {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
	}

	@media (max-width: 1024px) {
		max-width: 300px;
	}
}

.subscribeFormContentInput {
	position: relative;
}

.subscribeFormInputAlert {
	position: absolute;
	left: calc(100% - 55px);
	top: calc(100% - 33px);
}
