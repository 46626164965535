.componentWrapper {
    min-height: 100vh;
}

.rc-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    margin: 25px 0;
    font-size: 16px;
    user-select: none;
    //background-color: #f7fafd;
}
.rc-pagination:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
}
.rc-pagination-item {
    cursor: pointer;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    list-style: none;
    display: flex;
    margin-right: 8px;
    outline: none;
}
.rc-pagination-item a {
    flex-grow: 1;
    text-decoration: none;
    color: #666;
}
.rc-pagination-item:hover {
    border-color: #66BB6A;
}
.rc-pagination-item:hover a {
    color: #66BB6A;
}
.rc-pagination-item-active {
    background-color: #66BB6A;
    border-color: #66BB6A;
    border-radius: 4px;
}
.rc-pagination-item-active a {
    color: #FFF;
}
.rc-pagination-item-active:hover a {
    color: #FFF;
    background-color: transparent;
}
.rc-pagination-jump-prev a:after,
.rc-pagination-jump-next a:after {
    content: "•••";
    display: block;
    letter-spacing: 2px;
    color: #CCC;
    font-size: 12px;
    margin-top: 1px;
}
.rc-pagination-jump-prev:hover a:after,
.rc-pagination-jump-next:hover a:after {
    color: #66BB6A;
}
.rc-pagination-jump-prev:hover a:after {
    content: "«";
}
.rc-pagination-jump-next:hover a:after {
    content: "»";
}
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
    margin-right: 8px;
}

.rc-pagination-next::before {
    content: 'Next';
    font-size: 16px;
    padding: 0 5px 0px 10px;
    color: #586069;
}

.rc-pagination-prev::after {
    content: 'Prev';
    font-size: 16px;
    padding: 0 10px 0px 5px;
    color: #586069;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
    display: flex;
    cursor: pointer;
    color: #666;
    font-size: 10px;
    border-radius: 6px;
    list-style: none;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    outline: none;
    svg {
        height: 28px;
        width: 28px;
    }
}
.rc-pagination-jump-prev a,
.rc-pagination-jump-next a {
    flex-grow: 1;
}
.rc-pagination-prev a:after {
    content: "‹";
    display: block;
}
.rc-pagination-next a:after {
    content: "›";
    display: block;
}
.rc-pagination-prev,
.rc-pagination-next {
    border: 1px solid #D9D9D9;
    font-size: 18px;
    background-color: white;
}
.rc-pagination-prev a,
.rc-pagination-next a {
    flex-grow: 1;
    color: #666;
}
.rc-pagination-prev a:after,
.rc-pagination-next a:after {
    margin-top: -1px;
}
.rc-pagination-disabled {
    cursor: not-allowed;
}
.rc-pagination-disabled a {
    color: #CCC;
}