.cookiesWrapper {
	position: fixed;
	left: calc(50% - 515px);
	bottom: 60px;
	z-index: 20;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	-webkit-appearance: none;
	border-radius: 15px;
	z-index: 31;

	@media (max-width: 1030px) {
		left: 0;
		margin: 0 50px;
	}

	@media (max-width: 900px) {
		flex-wrap: wrap;
	}

	@media (max-width: 500px) {
		left: 0;
		margin: 0 20px;
	}
}

.cookiesContent {
	display: flex;
	padding: 20px 30px;
	box-sizing: border-box;
	max-width: 1030px;
	width: 100%;

	span {
		font-size: 14px;
		color: #959da5;
		line-height: 1.5;
	}

	@media (max-width: 768px) {
		flex-wrap: wrap;
	}

	@media (max-width: 500px) {
		padding: 20px 15px;
	}
}

.cookiesButton {
	width: 144px;
	height: 50px;
	margin: 0 20px 0 0;
	background: transparent;
	border-radius: 8px;
	border: 2px solid #959da580;
	outline: none;
	color: #959da5;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;
	white-space: nowrap;

	&:hover {
		box-shadow: 0px 0px 10px rgba(149, 157, 165, 0.5);
		-webkit-appearance: none;
	}

	&:active {
		background: #959da5;
		color: white;
	}

	@media (max-width: 500px) {
		margin: 0 10px 0 0;
	}
}

.cookiesReadMore {
	margin: 0 20px 0 0;
	padding: 19px 44px;
	text-decoration: none;
	background: #72c676;
	border-radius: 8px;
	border: none;
	outline: none;
	color: #ffffff;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;
	white-space: nowrap;

	&:hover {
		box-shadow: 0px 0px 10px rgba(102, 187, 106, 0.65);
		-webkit-appearance: none;
	}

	&:active {
		background: #66bb6a;
	}

	@media (max-width: 500px) {
		margin: 0 10px 0 0;
	}
}

.cookiesContentWrapper {
	padding-right: 40px;

	p {
		margin: 0;
	}

	strong {
		color: #66bb6a;
		font-weight: initial;
	}

	@media (max-width: 768px) {
		padding-right: 0;
	}
}

.cookiesButtonWrapper {
	display: flex;
	align-items: center;
}

.collapsCookies {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #959da5;
	margin-top: 10px;
	text-decoration: underline;
	font-size: 14px;

	svg {
		width: 15px;
		margin-left: 10px;
		fill: #959da5;
		transform: rotate(270deg);
	}

	@media (max-width: 768px) {
		margin-bottom: 10px;
	}
}

.expandCookies {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #959da5;
	margin-top: 10px;
	text-decoration: underline;
	font-size: 14px;

	svg {
		width: 15px;
		margin-left: 10px;
		fill: #959da5;
		transform: rotate(90deg);
	}

	@media (max-width: 768px) {
		margin-bottom: 10px;
	}
}
