.header {
	position: sticky;
	border-bottom: 1px solid #e1e4e8;
	background-color: white;
	z-index: 30;
	top: 0;
	transition: 400ms;

	.stickyHeaderContent {
		border-bottom: none;
	}
}

.homeHeader {
	position: sticky;
	background-color: transparent;
	z-index: 30;
	top: 0;
	transition: 400ms;

	.headerLink {
		color: white;

		&:hover {
			text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
		}

		svg {
			fill: white;
		}
	}

	.headerLinkOpened {
		color: white;

		&:hover {
			text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
		}

		svg {
			fill: white;
		}
	}

	.headerDevider {
		border-left-color: white;
	}

	.socialIconsWrapper {
		svg {
			fill: white;

			&:hover {
				fill: #66bb6a;
			}
		}
	}

	.logoWrapper {
		background-color: transparent;
	}
}

.headerContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	height: 90px;
	margin: 0 auto;
	padding: 0 30px;
	box-sizing: border-box;
	transition: 200ms;

	.headerLinkWrapper {
		height: 91px;
		display: flex;
		align-items: center;
	}

	.activeHeaderLink {
		height: auto;
		line-height: 2.4;
		border-bottom: 1px solid #000;
	}

	@media (max-width: 990px) {
		padding: 0 15px;
	}
}

.stickyHeaderContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	height: 90px;
	margin: 0 auto;
	padding: 0 30px;
	box-sizing: border-box;
	background-color: white;
	transition: 200ms;
	border-bottom: 1px solid #e1e4e8;

	.headerLinkWrapper {
		height: 89px;
		display: flex;
		align-items: center;
	}

	.headerLink {
		color: inherit;

		svg {
			fill: inherit;
		}
	}

	.headerLinkOpened {
		color: inherit;

		svg {
			fill: inherit;
		}
	}

	.headerDevider {
		border-left-color: inherit;
	}

	.socialIconsWrapper {
		svg {
			fill: #959da5;

			&:hover {
				fill: #66bb6a;
			}
		}
	}

	.activeHeaderLink {
		height: auto;
		line-height: 2.4;
		border-bottom: 1px solid #000;
	}

	@media (max-width: 990px) {
		padding: 0 15px;
	}
}

.socialIconsWrapper {
	display: flex;
	flex-direction: row;
	margin-left: 30px;

	a {
		margin: 0 5px;

		svg {
			width: 24px;
			height: 28px;
			fill: #959da5;

			&:hover {
				fill: #66bb6a;
			}
		}
	}

	@media (max-width: 1300px) {
		display: none;
	}
}

.logoWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 64px;
	height: 64px;
	background-color: #ffffff;
}

.logoIcon {
	width: 64px;
	height: 64px;
}

.headerCovidButton {
	border: none;
	background-color: transparent;
	cursor: pointer;
	outline: none;

	img {
		max-width: 80px;
		max-height: 48px;
	}
}

.headerLinkWrapper {
	position: relative;
	padding: 0 28px;

	@media (max-width: 1440px) {
		padding: 0 20px;
	}

	@media (max-width: 1130px) {
		padding: 0 10px;
	}
}

.headerLink {
	display: flex;
	align-items: center;
	line-height: 2.4;
	height: auto;
	color: black;
	font-size: 17px;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		opacity: 0.8;
	}

	svg {
		width: 15px;
		margin-left: 20px;
		transform: rotate(90deg);
	}

	@media (max-width: 990px) {
		svg {
			margin-left: 5px;
		}
	}
}

.headerLinkOpened {
	display: flex;
	align-items: center;
	height: 100%;
	font-size: 24px;
	color: black;
	font-size: 17px;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		opacity: 0.8;
	}

	svg {
		width: 15px;
		margin-left: 20px;
		transform: rotate(270deg);
	}

	@media (max-width: 990px) {
		svg {
			margin-left: 5px;
		}
	}
}

.headerMenuWrapper {
	display: flex;
	align-items: center;
}

.headerDevider {
	border-left: 1px solid black;
	height: 15px;
}

.stickyHeaderLinkDropdown {
	position: absolute;
	position: absolute;
	top: calc(100% - 20px);
	background-color: rgba($color: #060810, $alpha: 0.55);
	border-radius: 4px;
	width: 100%;
	left: 0;

	.menuDropdownLink {
		color: white;
	}
}

.headerLinkDropdown {
	position: absolute;
	top: 100%;
	background-color: white;
	width: 100%;
	left: 0;
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
	-webkit-appearance: none;
	border-radius: 4px;
}

.stickyHeaderLinkDropdownService {
	position: absolute;
	position: absolute;
	top: calc(100% - 20px);
	background-color: rgba($color: #060810, $alpha: 0.55);
	border-radius: 4px;
	width: 170px;
	left: 0;

	.menuDropdownLink {
		color: white;
	}
}

.headerLinkDropdownService {
	position: absolute;
	top: 100%;
	background-color: white;
	width: 170px;
	left: 0;
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
	-webkit-appearance: none;
	border-radius: 4px;
}

.headerDropdownMenu {
	display: flex;
	flex-direction: column;
	padding: 5px 10px 10px;
}

.menuDropdownLink {
	text-decoration: none;
	color: black;
	padding: 5px 0;

	&:hover {
		color: #66bb6a;
		font-weight: 600;
	}
}

.infoWithSocialWrapper {
	display: flex;
	align-items: center;
}

.headerCovidWrapper {
	position: relative;

	@media (max-width: 430px) {
		display: none;
	}
}

.menuHeaderWrapper {
	display: flex;
	align-items: center;
}

.menuHeader {
	margin-left: 20px;
	cursor: pointer;
}

.languageContainerSticky {
	position: relative;
	font-family: 'MuseoSans-500', sans-serif;
	font-size: 18px;
	color: #000;
	margin-right: 20px;
	cursor: pointer;

	svg {
		stroke: #000;
		margin-left: 15px;
		height: 10px;

		&:hover {
			opacity: 0.8;
		}
	}

	&:hover {
		opacity: 0.8;
	}
}

.languageContainer {
	position: relative;
	font-family: 'MuseoSans-500', sans-serif;
	font-size: 18px;
	color: #fff;
	margin: 0 20px;
	cursor: pointer;

	svg {
		fill: transparent;
		stroke: #fff;
		margin-left: 15px;
		height: 10px;

		&:hover {
			opacity: 0.8;
		}
	}

	&:hover {
		opacity: 0.8;
	}
}

.languageDropdown {
	position: absolute;
}

.languageSelectorOpened {
	svg {
		transform: rotate(180deg);
	}
}

.languageDropdown {
	padding: 10px 0;
}

.languageDropdownValue {
	&:hover {
		opacity: 0.8;
	}
}