@import '../../common/Modal/ModalExports.scss';

.menuModalOverlay {
    @include overlay;

    background-color: #66BB6A;
}

.menuModalContent {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menuLinksWrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 40px 0 0 20px;
    max-width: 375px;
}

.menuLinks {
    display: flex;
    justify-content: space-between;
}

.menuLink {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
    padding: 5px 0;
    transition: all 0.2s ease-in-out;

    svg {
        margin-left: 25px;
        height: 14px;
        fill: white;
    }
    
    &:hover {
        color: #24292e;

        a {
            color: #24292e;
        }

        svg {
            fill: #24292e;
        }
    }
}

.menuLinksContainer {
    position: relative;
}

.menuLinkOpened {
    padding-left: 20px;
}

.menuExpertiseLinks {
    margin-left: 40px;
}

.menuTitle {
    text-align: center;
    font-size: 32px;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-family: 'MuseoSans-700', sans-serif;
    color: #ffffff;
}

.menuCloseWrapper {
    position: absolute;
    display: inline-block;
    top: 10px;
    right: 10px;
    z-index: 1;
    cursor: pointer;

    svg {
        stroke: #ffffff;
    }
}

.menuBackgraundWrapper {
    position: absolute;
    top: 0;
    z-index: -1;
    display: flex;
    width: 100%;
}

.menuModalVector {
    display: flex;
    height: 100%;
    fill: #A5D6A74d;
}

.menuModalSocialWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px;
    svg {
        margin-right: 10px;
        fill: white;
    }
}

.menuModalCovidWrapper {
    display: none;
    
    @media (max-width: 430px) {
        display: block;
    }
}

.menuModalCovidButton {
    
    img {
        width: 80px;
    }
}

.openMenu {
    transform: rotate(90deg);
}

.languageContainerMob {
	position: relative;
	font-family: 'MuseoSans-500', sans-serif;
	font-size: 18px;
	color: #fff;
	margin-right: 20px;
	padding-bottom: 30px;
	cursor: pointer;

	svg {
		stroke: #fff;
        fill: none;
		margin-left: 15px;
		height: 10px;

		&:hover {
			opacity: 0.8;
		}
	}

	&:hover {
		opacity: 0.8;
	}
}