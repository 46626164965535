.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 60px;
	background: linear-gradient(180deg, #ffffff 0%, #f6f7f9 100%);
}

.footerLinks {
	flex-wrap: wrap;
	display: flex;
	justify-content: center;
}

.footerLink {
	font-weight: 300;
	font-size: 11px;
	text-decoration: none;
	text-transform: uppercase;
	color: #24292e;
	margin-top: 10px;
	padding: 0 15px;
	border-right: 1px solid #24292e;
	cursor: pointer;

	&:hover {
		text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.55);
	}

	&:last-child {
		border-right: none;
	}
}

.footerSocialIconsWrapper {
	display: flex;
	flex-direction: row;

	a {
		margin: 30px 5px 55px;

		svg {
			width: 24px;
			height: 28px;
			fill: #24292e;

			&:hover {
				fill: #66bb6a;
			}
		}
	}
}

.footerEndCopyrightWrapper {
	background-color: #ffffff;
	width: 100%;
}

.footerEndCopyright {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1200px;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 20px;

	@media (max-width: 450px) {
		padding: 20px 15px;
	}
}

.footerCopyright {
	font-size: 11px;
	color: #24292e;

	@media (max-width: 450px) {
		span {
			display: none;
		}
	}
}

.сertificatesWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 310px;
	height: 134px;
	margin-bottom: 40px;
	background-color: white;
	border-radius: 16px;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;
}

.certificateImage {
	width: 270px;
	height: 95px;
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}
