@font-face {
	font-family: 'MuseoSans-500';
	src: url('./fonts/MuseoSansCyrl-500.eot');
	src: url('./fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
		url('./fonts/MuseoSansCyrl-500.woff') format('woff'), url('./fonts/MuseoSansCyrl-500.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'MuseoSans-700';
	src: url('./fonts/MuseoSansCyrl-700.eot');
	src: url('./fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
		url('./fonts/MuseoSansCyrl-700.woff') format('woff'), url('./fonts/MuseoSansCyrl-700.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'MuseoSans-900';
	src: url('./fonts/MuseoSansCyrl-900.eot');
	src: url('./fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
		url('./fonts/MuseoSansCyrl-900.woff') format('woff'), url('./fonts/MuseoSansCyrl-900.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'SegorUI';
	src: local('SegorUI'), url('./fonts/SegoeUI.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'SegorUI-Light';
	src: local('SegorUI'), url('./fonts/SegoeUI-Light.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'SegorUI-SemiBold';
	src: local('SegorUI'), url('./fonts/SegoeUI-SemiBold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'SegorUI-Bold';
	src: local('SegorUI'), url('./fonts/SegoeUI-Bold.ttf') format('truetype');
	font-display: swap;
}

body {
	margin: 0;
	font-family: 'SegorUI', sans-serif;
}

.ReactModal__Overlay {
	z-index: 100;
}

.extraStaffContactButton {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	bottom: 0;
	right: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	z-index: 1;
}

.extraStaffContactText {
	display: none;
}

@media (max-width: 768px) {
	.extraStaffContactText {
		display: inline-block;
		text-transform: uppercase;
		font-weight: 600;
		color: #24292e;
	}

	.extraStaffContactButton {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		background-color: #ffffff;
		width: 100%;
	}

	.extraStaffContactButton svg {
		width: 100px;
		height: 60px;
	}

	.closeExtraStaffContact {
		display: none;
	}
}

.closeExtraStaffContact svg {
	width: 30px;
	height: 30px;
}

.closeExtraStaffContact {
	position: absolute;
	top: -10px;
	right: -5px;
	background-color: initial;
	border: none;
	cursor: pointer;
	outline: none;
}

.closeExtraStaffContact svg {
	stroke: #959da5;
}

/* Mapbox */

.mapboxgl-popup-tip {
	border: none;
}

.mapboxgl-popup-content {
	background: inherit;
	padding: 0;
}

.mapboxgl-marker {
	outline: none;
}

/* Calendar styles */

.rdt {
	position: relative;
}
.rdtPicker {
	display: none;
	position: absolute;
	width: 252px;
	padding: 4px;
	margin-top: 1px;
	z-index: 10;
	background: #fff;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	-webkit-appearance: none;
	border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
	display: block;
}
.rdtStatic .rdtPicker {
	box-shadow: none;
	position: static;
}

.rdtPicker .rdtTimeToggle {
	text-align: center;
}

.rdtPicker table {
	width: 100%;
	margin: 0;
}
.rdtPicker td,
.rdtPicker th {
	text-align: center;
	height: 28px;
}
.rdtPicker td {
	cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
	background: #eeeeee;
	cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
	color: #999999;
}
.rdtPicker td.rdtToday {
	position: relative;
}
.rdtPicker td.rdtToday:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-bottom: 7px solid #428bca;
	border-top-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	bottom: 4px;
	right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
	background-color: #428bca;
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
	border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
	color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker th {
	border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
	width: 14.2857%;
	border-bottom: none;
	cursor: default;
}
.rdtPicker th.rdtSwitch {
	width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
	font-size: 21px;
	vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
	display: block;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
	cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
	background: #eeeeee;
}

.rdtPicker tfoot {
	border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
	border: none;
	background: none;
	cursor: pointer;
}
.rdtPicker button:hover {
	background-color: #eee;
}

.rdtPicker thead button {
	width: 100%;
	height: 100%;
}

td.rdtMonth,
td.rdtYear {
	height: 50px;
	width: 25%;
	cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
	background: #eee;
}

.rdtCounters {
	display: inline-block;
}

.rdtCounters > div {
	float: left;
}

.rdtCounter {
	height: 100px;
}

.rdtCounter {
	width: 40px;
}

.rdtCounterSeparator {
	line-height: 100px;
}

.rdtCounter .rdtBtn {
	height: 40%;
	line-height: 40px;
	cursor: pointer;
	display: block;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}
.rdtCounter .rdtBtn:hover {
	background: #eee;
}
.rdtCounter .rdtCount {
	height: 20%;
	font-size: 1.2em;
}

.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
}

.rdtMilli input {
	width: 100%;
	font-size: 1.2em;
	margin-top: 37px;
}

.rdtTime td {
	cursor: default;
}

input[type='radio'] {
	position: relative;
	height: 15px;
	width: 15px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
}

input[type='radio']::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border: 1px solid #66bb6a;
}

input[type='radio']:checked::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #66bb6a;
	transform: translate(-50%, -50%);
	visibility: visible;
}
