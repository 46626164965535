@keyframes overlayAppearing {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@mixin overlay {
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	outline: none;
	background-color: rgba(#24292E, 0.7);
	animation: overlayAppearing 0.3s;
}